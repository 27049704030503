import { envConfig } from "./env";

export const FAN_CLUB_TITLE = "SPWN";
/**
 * @todo 共通のdescriptionが決定したら差し替える。
 */
export const FAN_CLUB_COMMON_DESCRIPTION =
  "ファンとのつながりを、つぎの次元へ。オールインワン・ファンブーストツール SPWN";
export const FAN_CLUB_APP_ORIGIN =
  envConfig.NODE_ENV === "development"
    ? "http://localhost:3000"
    : `https://${envConfig.NEXT_PUBLIC_VERCEL_URL}`;
